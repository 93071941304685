@import "../../mixin.scss";

.container {
  // background-color: wheat; // Dell
  // height: 880px;
  position: relative;
  display: flex;
  flex-direction: column;
  // margin-bottom: 64px;
}

.blockTitle {
  display: flex;
  // padding-left: 443px;
  @include marginLeftTitelContent;
  // background-color: darkblue; // Dell
}

// .blockSlider {
//   display: flex;
//   background-color: darkcyan; // Dell
// }

.blockSliderContent {
  display: flex;
  flex-direction: row;

  @include gapBlockSkiderPhoto;
  // background-color: rgb(117, 199, 35); // Dell
  align-self: start;
}

.blockSliderSwiperContent {
  display: flex;
  // width: 1060px;
  // height: 750px;
  @include sizeSliderBlock;
  // flex: 1;
  flex-direction: row;
  // background-color: violet; // Dell
}

.blockButtonSlide {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;

  // background-color: rgb(148, 90, 49); // Dell
  img {
    width: 64px;
    height: 64px;
  }
}

.blockSwiperSlideMiniContent {
  display: flex;
  flex-direction: column;
  // flex: 1;
  // align-self: flex-end;
  justify-content: center;
  // align-items: flex-start;
  min-height: 548.63px;
  max-height: 750px;
  // width: 542px;
  // background-color: rgb(57, 196, 117); // Dell
  // align-self: start;
  // margin-top: auto;
  // margin-bottom: auto;
}

.blockSoudSlider {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  // background-color: forestgreen; // Dell
  align-self: start;
}

.blockPhoto {
  display: flex;
  flex-direction: row;
  align-self: start;
  height: 100%;
  width: 100%;
  // width: 1060px;
  // height: 750px;
  gap: 45px;
  // background-color: darkgray; // Dell
}

.blockDiscriptSLider {
  display: flex;
  align-self: start;
  margin-top: 32px;

  margin-bottom: 40px;
}

.blockDiscriptSound {
  // background-color: cadetblue; // Dell
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.buttonSliderSwitchS {
  // background-color: #c3692b;
  padding: 24px 32px 24px 32px;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  flex: 1;
  border: 0px;
  line-height: 24.2px;
  cursor: pointer;
}

.contentButtonSlider {
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  // width: 1060px;
  min-width: 865px;
  max-width: 1060px;
  // background-color: rgb(58, 170, 129); // Dell
}

.wrapperSlider {
  // margin-top: 40px;
  @include marginTopDiscriptMakingBeer;
  // margin-left: 443px;
  @include showSliderSwipBeer;
  // display: flex;
  flex-direction: column;
  align-self: center;
  // background-color: rgb(51, 70, 64); // Dell
}

.blockPhotoSliderMain {
  display: flex;
  align-self: start;
  @include sizeSliderBlockPhoto;
  // width: 473px;
  // height: 100%;
  height: 548.63px;
  // height: 750px;
  margin-top: auto;
  margin-bottom: auto;
  // background-color: rgb(54, 196, 196); // Dell
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.blockMobileWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  // background-color: cadetblue; //Dell
  @include viewMobileFlex;
}

.contentBeer {
  display: flex;
  @include contentBeerMobileVersion;
  // background-color: chartreuse; // Dell
}

.photoBeerMobileSize {
  width: 240.65px;
  height: 381.59px;
  margin-left: auto;
  margin-right: auto;
  // background-color: darkgoldenrod; // Dell
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blockTextBeerSliderMobile {
  display: flex;
  flex-direction: column;
  flex: 1;
  // background-color: rgb(80, 0, 100); // Dell
  margin-top: auto;
  margin-bottom: auto;
}

.blockTitelSliderAddCorner {
  // background-color: rgb(89, 141, 165); // Dell
  position: relative;
}

.blockDiscriptMobile {
  // background-color: rgb(67, 110, 23); // Dell
  display: flex;
  // margin-top: 16px;

  // margin-bottom: 24px;
  @include marginBlockDiscriptMobile;
}

// TEXT

.textTitel {
  color: #fff;
  // font-size: 72px;
  // font-weight: 400;
  @include textTitelContent;
}

.textSliderSwiperTitel {
  color: #fff;
  @include textSizeMobileVBeer;
}

.textSliderSwiperDiscript {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.textSliderSwiperTitelRed {
  color: #c3692b;
  @include textSizeMobileVBeer;
  // font-size: 46px;
  // font-weight: 280;
}

.textSoundSlider {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}
