@import "../../mixin.scss";
.container {
  // background-color: rgb(55, 211, 211); // Dell
  // height: 880px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  position: relative;
}

.blockTitle {
  display: flex;
  // padding-left: 443px;
  @include marginLeftTitelContent;
  // background-color: darkblue; // Dell
}

.blockDiscript {
  // width: 506px;
  @include widthBlockDiscript;
  @include marginLeftBlockDiscript;
  display: flex;
  // background-color: darkcyan; // Dell
  @include marginTopDiscriptMakingBeer;
  white-space: pre-wrap;
}

.blockImgBeer {
  @include marginLeftImgMakingBeer;
  // background-color: red; // Dell
  // -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: scroll;
  // flex-direction: row;
  // scroll-direction: horizontal;
  // scroll-behavior: horizontal;
  // scroll-margin-block: 100%;
  height: 500px;
  width: 700px;
}

.blockImgCraftBeer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  @include marginLeftImgMakingBeer;
  clip-path: inset(3px 3px 6.5px 3px);
  width: 100%;
  margin-right: 120px;
  // background-color: aqua; //Dell
  // overflow: hidden;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none;
  overflow-y: hidden;
  // -webkit-overflow-scrolling: touch;
  // width: 100%;
  // scroll-direction: horizontal;
  position: relative;
  overflow-x: scroll;

  // flex: 1;

  // img {
  //   object-fit: contain;
  //   @include scrollBeerImgSwitchCraftBeer;
  // }
}

.blockImgConerAbs {
  @include switchIconBeerCorner;
  z-index: -1;
  // top: 381px;
  // display: flex;
  // position: absolute;
  // left: 0px;
  img {
    background-repeat: no-repeat;
    // background-image: url("../../public/img/Tubes/corner/Mob.png");
  }
}
.imgBeerW {
  // flex: 0 0 auto;
  object-fit: contain;
  background-repeat: no-repeat;

  // background-size: 100% 100%;
  display: block;
  @include scrollBeerImgSwitchCraftBeer;
}

.blockImgCraftBeer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

// .blockImgCraftBeer::-webkit-scrollbar {
//   width: 0;
// }

.blockScroll {
  // margin-top: 26.05px;
  @include marginLeftImgMakingBeerScroll;
  // margin-bottom: 64px;
  display: flex;
  // background-color: aquamarine; // Dell
  justify-content: center;
  clip-path: inset(3px);
  img {
    @include scrollBeerImgSwitch;
    clip-path: inset(4px);

    background: no-repeat;
  }
}

// TEXT

.textTitel {
  color: #fff;
  @include textTitelContent;
}

.textDiscript {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
