@import "../../variables";
@import "../../mixin.scss";

.container {
  display: flex;
  flex-direction: column;
  // margin: 0px;
  // padding: 0px;
  // background-color: wheat; // Dell
  //   max-width: 1920px;
  //   min-width: 1920px;
  // height: 1004px;
  // width: 840px;
  min-width: 100%;
  height: 100vh;
  // justify-content: space-between;
  position: relative;
  // border: 1px solid rgb(199, 32, 32);
  header {
    // min-height: 46px;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    // background-color: rgb(0, 139, 109); // Dell
    justify-content: space-between;
  }
}

.blockShowNavbar {
  display: flex;
  margin-right: 16px;
  margin-top: 16px;
  // min-width: 100%;
  @include showNavbarPcNAvbar;
  // background-color: aquamarine; //Dell
}

.blockHeader {
  // background-color: forestgreen; // Dell
  justify-content: space-between;
  padding: 16px 20px;
  flex: 1;
  @include showNavbarPC;
}

.blockBurger {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  // background-color: rgb(112, 107, 112); // Dell
}

.blockClose {
  display: flex;
  margin-right: 16px;
  align-self: start;
  justify-content: flex-end;
  // justify-content: flex-end;
  height: 64px;
  width: 100%;
  // flex-direction: row;
  // background-color: aqua; // Dell
}

.blockModal {
  flex-direction: column;
  box-sizing: border-box;
  top: 0px;
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100%;
  // width: 598px;
  // @include wrapperContent;
  // flex: 1;
  // width: 100vh;
  padding: 16px;
  // background-color: hotpink; // Dell
  background-color: #20253c;
}

.blockHeaderContent {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: 128px;
  // background-color: darkcyan; // Dell
}

.modalBurger {
  // background-color: rebeccapurple; // Dell
  // width: 100%;
}

.blockTitle {
  // background-color: rgb(45, 170, 41); // Dell
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  // @include marinTopLogo;
  @include marinTopOpenPub;
}

.blockTab {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-self: start;
  position: relative;
  //   background-color: cadetblue; // Dell
  button {
    cursor: pointer;
    display: flex;
    border: 0px;
    background-color: transparent;
    // background-color: aquamarine; // Dell
    border-radius: 2px;
    padding: 12px 16px 12px 16px;
    @extend .textTableft;
  }
}

.blockHeaderContent {
  display: flex;
  flex-direction: column;
}

.contactBlock {
  display: flex;
  //   background-color: wheat; // Dell
  gap: 8px;
  align-items: center;
  align-self: start;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;

  button {
    cursor: pointer;

    display: flex;
    border: 0px;
    box-sizing: border-box;
    text-decoration: none;
    background-color: transparent;
    padding: 12px 16px 12px 16px;
    // background-color: aquamarine; // Dell
    border-radius: 2px;
    @extend .textTableft;
    // h1 {
    //   @extend .textTableft;
    // }
  }
}

.blockTitelText {
  display: flex;
  flex-direction: column;
  //   background-color: darkgray; // Dell
  gap: 20px;
  align-items: center;
  text-align: center;
  h1 {
    @extend .textTitelContent;
  }
  p {
    @extend .textDiscriptContent;
  }
}

.blockMap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  //   align-self: start;
  @include marginBottomMap;
  // background-color: rebeccapurple; // Dell
}

.blockTextContentHeader {
  display: flex;
  padding: 12px 16px;
  border: 0px;
  flex: 1;
  background-color: transparent;
}

.logoBeerBird {
  display: flex;
  @include sizeLogoBeer;
  // background-color: aquamarine; // Dell
  img {
    width: 100%;
    height: 100%;
  }
}

.blockOpen {
  // background-color: aquamarine; // Dell
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 32px;
}

/// TEXT
///
.textTableft {
  color: #fff;
  font-style: normal;
  @include sizeButtonHeader;
  // font-size: 16px;
  // font-weight: 500;
  // line-height: 20px;
}

.textOpenPub {
  color: #e6702d;
  @include textSizeOpenPub;
  // font-family: $fontFamilyGlobal;
  // font-size: 46px;
  // font-style: normal;
  // font-weight: 280;
  // line-height: 55.2px;
}

.textTitelContent {
  color: #fff;
  // font-size: 36px;
  // font-style: normal;
  // font-weight: 700;
  // line-height: 20px;
  @include sizeTitelHeader;
}

.textDiscriptContent {
  color: #fff;
  // font-family: $fontFamilyGlobal;
  // font-size: 28px;
  // font-style: normal;
  // font-weight: 700;
  // line-height: 20px;
  @include sizeDiscriptHeader;
}

.textMap {
  color: #e6702d;

  // font-family: $fontFamilyGlobal;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
}

.textheaderContent {
  color: #fff;
  // font-family: $fontFamilyGlobal;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
