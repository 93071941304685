@import "../../mixin.scss";
.container {
  // background-color: wheat; // Dell
  // margin-top: 49px;
  // height: 880px;
  // margin-bottom: 49px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.blockTitle {
  display: flex;
  // margin-left: 443px;
  height: fit-content;
  @include marginLeftTitelContent;
  // background-color: darkblue; // Dell
}

.blockDiscript {
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 40px;
  @include marginTopDiscriptMakingBeer;
  // background-color: darkblue; // Dell
  @include marginLeftBlockDiscript;
  @include widthBlockDiscript;
  display: flex;
  flex-direction: column;
  gap: 40px;
  // width: 506px;
}

.blockDiscriptButton {
  // display: flex;
  // flex-direction: row;
  // background-color: chartreuse; //Dell
  display: grid;
  @include gridBlockAboutFoodButton;
  gap: 16px;
}

.buttonDiscript {
  background-color: #e6702d;
  padding: 24px 0px 24px 0px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  @include buttonContentSize;
  cursor: pointer;

  color: #fff;
  // font-size: 20px;
  // font-weight: 500;
}

.blockPhotoAboutFood {
  z-index: 3;
  // background-color: rgb(130, 238, 220); // Dell
  display: grid;
  @include blockPhotoAbout;
  box-sizing: border-box;
  padding: 20px 0px;
  @include magrginLeftAboutFootPhoto;
  // margin-left: auto;
  // margin-right: auto;
  // align-self: start;
}
.blockHoverMainAboutFood {
  display: none;
}

.blockMainPhotoAboutFood {
  flex: 1;
  position: relative;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .blockHoverMainAboutFood {
      @include showDopInfaMainPhoto;
      position: absolute;
      // display: none;

      // display: flex;
      flex-direction: column;
      justify-content: space-between;
      // margin: 16px;
      left: 16px;
      top: 16px;
      bottom: 16px;
      right: 16px;
      padding: 24px 16px;
      background-color: #c3692b;
    }
  }
}

.blockDiscriptPhoto {
  @include showPcVersion;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  // display: flex;
  gap: 20px;
  // flex-direction: row;
  // flex-wrap: wrap;
}

.blockDiscriptMobile {
  // display: grid;
  @include showMobVerisonDisrc;
  @include heightSizeBlock;

  grid-template-columns: repeat(1, 1fr);
  // grid-template-rows: repeat(3, 283px);
  // gap: 8px;
  row-gap: 8px;
}

.blockContentMobDiscriprt {
  display: flex;
  // flex: 1;
  justify-content: space-between;
  // display: grid;
  gap: 8px;
  // grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: 1fr;
  // background-color: forestgreen; // Dell
}

.blockPhotoMobile {
  display: flex;
  width: 50%;
  // flex-grow: 1;
  // background-color: rgb(129, 127, 255); // Dell
  height: 100%;
  // flex: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blockTextHMobile {
  display: flex;
  flex-grow: 1;
  width: 50%;
  border: 1px solid #c3692b;
  border-radius: 2px;
  justify-content: space-between;
  flex-direction: column;

  padding: 24px;
  box-sizing: border-box;
}

.blockHoverMiniDiscript {
  display: none;
}

.blockContentDiscriptPhoto {
  // display: block;
  // width: 375px;
  // height: 376px;
  // flex: 1;
  // height: auto;
  display: inline-block;
  overflow: hidden;
  // width: 100%;
  // height: 100%;
  // width: 275px;
  // height: 275px;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 100%;
    // display: flex;
    // flex: 1;
    object-fit: cover;
  }
  &:hover {
    .blockHoverMiniDiscript {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      background-color: #c3692b;
      position: absolute;
      padding: 16px 8px;
      left: 16px;
      top: 16px;
      bottom: 16px;
      right: 16px;
    }
  }
}

.blockTextMainAboutFood {
  position: absolute;
  flex-direction: column;
  @include showDopInfaMainPhoto;
  // display: flex;
  gap: 8px;
  left: 48px;
  right: 48px;
  bottom: 48px;
  // background-color: sandybrown; // Dell
}

.blockHoverMainAboutFood {
  // position: absolute;
  display: none;

  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // // margin: 16px;
  // left: 16px;
  // top: 16px;
  // bottom: 16px;
  // right: 16px;
  // padding: 24px 16px;
  // background-color: #c3692b;
}

// .blockHoverMainAboutFood::hover + .blockHoverMainAboutFood {
//   display: flex;
//   position: absolute;
//   // display: none;

//   display: block;
//   flex-direction: column;
//   justify-content: space-between;
//   // margin: 16px;
//   left: 16px;
//   top: 16px;
//   bottom: 16px;
//   right: 16px;
//   padding: 24px 16px;
//   background-color: #c3692b;
// }

// TEXT

.textTitel {
  color: #fff;
  // font-size: 72px;
  // font-weight: 400;
  @include textTitelContent;
}

.textDiscript {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.textTitelMainPhoto {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  line-height: 33.6px;
}

.textDiscriptMainPhoto {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.textHoverTitelMain {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.textHoverDiscriptMain {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}

.textMobileDiscriptHTitel {
  color: #fff;
  // font-size: 18px;
  // font-weight: 280;
  @include modDiscriptTitelSize;
}

.textMobileDiscriptHDis {
  @include showMobh2Discript;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}
