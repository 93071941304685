@import "../../../mixin.scss";
.wrapper {
  z-index: 4;
  top: 0px;
  left: 0px;
  background-color: #20253c;
  display: flex;
  position: fixed;
  width: 100%;
  flex-direction: row;
  height: 100vh;
  //   gap: 24px;
  overflow-y: hidden;
}

.blockModal {
  flex-direction: column;
  box-sizing: border-box;
  top: 0px;
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100%;
  // width: 598px;
  // @include wrapperContent;
  // flex: 1;
  // width: 100vh;
  padding: 16px;
  // background-color: hotpink; // Dell
  background-color: #20253c;
}

.blockClose {
  display: flex;
  margin-right: 16px;
  align-self: start;
  justify-content: flex-end;
  // justify-content: flex-end;
  height: 64px;
  width: 100%;
  cursor: pointer;
  // flex-direction: row;
  // background-color: aqua; // Dell
}

.blockHeaderContent {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: 128px;
  // background-color: darkcyan; // Dell
}

.modalBurger {
  // background-color: rebeccapurple; // Dell
  // width: 100%;
}

.blockButtonnavBar {
  display: flex;
  flex-direction: column;
  //   background-color: red; //Dell
}

.blockTextContentHeader {
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  border: 0px;
  flex: 1;
  background-color: transparent;
  @extend .textheaderContent;
}

// TEXT
.textheaderContent {
  color: #fff;
  // font-family: $fontFamilyGlobal;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.6px;
}
