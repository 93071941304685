@import "../../../mixin.scss";
@import "../../../variables";
.wrapper {
  z-index: 4;
  top: 0px;
  left: 0px;
  background-color: #0e0f0f;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
}

.container {
  z-index: 5;
  background-color: #20253c;
  border-radius: 4px;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 972px;
  gap: 20px;
  //   height: 550px;
  align-items: center;
  flex-direction: column;
  display: flex;
  @include textSizeTielPopup;
  span {
    a {
      text-decoration: none;
    }
  }
  button {
    cursor: pointer;

    border: 2px solid #e6702d;
    border-radius: 2px;
    color: #e6702d;
    padding: 24px 32px;
    background-color: transparent;
    font-size: 20px;
    font-weight: 500;
    width: 300px;
  }
}

.blockBirdPopupLeft {
  display: flex;
  position: absolute;
  left: -122px;
  top: -122px;
}

.blockBirdPopupRight {
  display: flex;
  position: absolute;
  right: -122px;
  top: -122px;
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

// @media (min-width: 453px) and (max-width: 768px) {
@media (max-width: $checkW6) {
  .blockBirdPopupLeft {
    display: none;
  }
  .blockBirdPopupRight {
    display: none;
  }
}

// TEXT

// .textTitel {
//   color: #fff;
//   @include textSizeTielPopup;
// }

// .textDiscript

// .textTitelRed {
//   @include textSizeTielPopup;
// }
