@import "../../mixin.scss";

.container {
  // background-color: saddlebrown; // Dell

  display: flex;
  flex-direction: column;
  position: relative;
}

.blockTitle {
  display: flex;

  @include marginLeftTitelContent;
  // background-color: darkblue; // Dell
}

.blockDiscript {
  // background-color: aquamarine; // Dell
  display: flex;
  flex-direction: column;

  // margin-top: 40px;
  @include marginTopDiscriptMakingBeer;
  @include marginLeftBlockDiscript;
  // margin-left: auto;
  // margin-right: auto;
  @include widthBlockDiscript;
}

.blockDiscriptTitel {
  display: flex;
  margin-top: 24px;
  margin-bottom: 8px;
}

.blockImgRestaurants {
  // margin-left: 707px;
  display: flex;
  // width: 718px;
  // height: 867px;
  @include marginLeftBlockDiscript;

  @include widthPhotoTakebeer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// TEXT

.textTitel {
  color: #fff;
  // font-size: 72px;
  // font-weight: 400;
  @include textTitelContent;
}

.textDiscriptTitel {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.textDiscrTimeContent {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
