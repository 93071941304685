@import "../../mixin.scss";
.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  // max-width: 1920px;
  // overflow: hidden;
  overflow: auto;
  // overflow-y: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  // background-color: beige; //Dell
}

.wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.container {
  display: flex;
  flex: 1 1;
  // width: 840px;
  overflow: hidden;
  flex-direction: column;
  // @include wrapperContent;
  // padding: 16px 32px;
  // background-color: rebeccapurple; // Dell
  // margin-left: auto;
  // margin-right: auto;
}

.photoHeader {
  // width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  // width: 100vw;
  height: 100vh; /// Венруть
  background-color: rgb(59, 117, 44); //Dell

  // height: 1004px;
  // background-color: rebeccapurple;
  // background-repeat-x: no-repeat;
  // background-repeat-y: no-repeat;
  // background-repeat: no-repeat;
  img {
    // @include headerMixinImg;
    width: 100%;
    object-fit: cover;
    height: 100%;
    background-repeat: no-repeat;
  }
}

.contentHomeGap {
  display: flex;
  flex-direction: column;
  @include gapColumnHome;
}
